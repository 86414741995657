import React, { createContext, useState } from "react";

export const ToastContext = createContext({});

export const SUCCESS = "success";
export const ERROR = "error";

const ToastProvider = ({ children }) => {
  const [toastVisibility, setToastVisibility] = useState(false);
  const [toastTitle, setToastTitle] = useState(null);
  const [toastContent, setToastContent] = useState(null);
  const [toastType, setToastType] = useState(SUCCESS);

  const clear = () => {
    setToastTitle(null);
    setToastContent(null);
    setToastVisibility(false);
    setToastType(SUCCESS);
  };
  return (
    <ToastContext.Provider
      value={{
        toastVisibility,
        setToastVisibility,
        toastTitle,
        setToastTitle,
        toastContent,
        setToastContent,
        toastType,
        setToastType,
        clear,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
