import "../styles/style.css";
import Head from "next/head";
import Script from 'next/script';
import ToastProvider from "../context/ToastContext";
import ModalProvider from "../context/ModalContext";
import "nprogress/nprogress.css";
import { Progress } from "../components/general/Progress";

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=G-4QJ4S4VLMC`} />
      <Script strategy="lazyOnload">
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-4QJ4S4VLMC', {
              page_path: window.location.pathname,
              });
          `}
      </Script>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#f6A925" />
        <link rel="apple-touch-icon" href="/icon-96x96.png" />
        <meta name="apple-mobile-web-app-status-bar" content="#f6A925" />
      </Head>
      <Progress />
      <ModalProvider>
        <ToastProvider>
          <Component {...pageProps} />
        </ToastProvider>
      </ModalProvider>
    </>
  );
}

export default MyApp;
